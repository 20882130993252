.gallery-view {

    .page__title {
        span {
            margin-right: 1.3rem;
            line-height: 1em;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .gallery-view__body {
        h1 {
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;
            font-size: 1.5em;
            text-transform: uppercase;
            margin: 1.5rem 7rem;
        }

        .carousel-body__wrapper {
            position: relative;
            overflow: hidden;
            margin: 0 7rem 2rem;
        }

        .carousel__prev, .carousel__next {
            background: $kae-blue;
            position: absolute;
            text-align: center;
            color: #fff;
            width: 2.7rem;
            height: 2.7rem;
            line-height: 2.7rem;
            font-size: 1.3rem;
            cursor: pointer;
            transition: all .1s ease;
            top: 50%;

            &:hover {
                background: $kae-dark-blue;
            }
        }

        .carousel__prev {
            left: 0;
        }
        .carousel__next {
            right: 0;
        }
    }

    .carousel__paging {
        @include clearfix();

        .gallery-collection__item {
            cursor: pointer;
            @include span(4);
            color: #FFFFFF;
        }
    }
}