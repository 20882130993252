.product__item {
    @include clearfix();
    display: block;
    position: relative;
    padding: 1rem 1.5rem 3.5rem 2rem;

    @media only screen and (max-width: 500px) {
        padding: 0 0 1.5rem;
    }

    &:hover {
        text-decoration: none;

        button {
            opacity: 1;

            @media only screen and (max-width: 500px) {
                opacity: 0;
            }
        }
    }

    &:nth-child(even) {
        background-color: #ffffff;
    }
    &:nth-child(odd) {
        background-color: $kae-light-blue;
    }

    .product-content {
        display: inline-block;
        vertical-align: top;
        width: span(9);
        padding-right: 1.5rem;

        @media only screen and (max-width: 500px) {
            padding: 0 1rem 1.5rem 1.5rem;
        }

        h3 {
            font-size: 1.3em;
            margin: 1em 0;
            text-transform: uppercase;
            font-family: 'Roboto Slab', serif;
            font-weight: 700;
        }

        p {
            color: #6e6e6e;
            margin: 0 0 1rem;
        }

            div {
            text-align: justify;
        }
    }
    .product-image {
        display: inline-block;
        vertical-align: top;
        width: span(2.9);

        @media only screen and (max-width: 500px) {
            width: span(3);
            float: left;
        }

        img {
            width: 100%;
            margin: 1em 0;
        }
    }

    button {
        opacity: 0;
        position: absolute;
        right: calc(25% + 3rem);
        bottom: 1rem;
        cursor: pointer;
        font-size: 1em;
        padding: 0.4rem 0.8rem;
        border: none;
        color: #ffffff;
        background-color: $kae-green;
    }
}