.section-page {

    &.senior-management, &.supervisory-board {
        article p:first-of-type .fancybox img {
            width: 100%;
            float: none;
            margin: 0 0 1rem;
        }

        table {
            thead {
                background: $kae-dark-blue;
                color: #ffffff;
            }
            tbody tr td:first-child {
                background: $kae-blue;
                color: #ffffff;
            }
        }
    }

    &.about-company {
        a[href$=".pdf"] {
            background: $kae-green !important;
            padding: 0.7rem 1.7rem;
            color: #ffffff;
            text-transform: uppercase;
            display: block;
            width: 45%;
            margin: 4rem auto 0;
            text-align: center;

            @media only screen and (max-width: 500px) {
                width: 100%;
            }

            &:hover {
                background: $kae-dark-green !important;
                text-decoration: none;
            }
        }
    }

    &.thermal-lens-manufacturing {
        article p:first-of-type .fancybox img {
            width: 100%;
            float: none;
            margin: 0 0 1rem;
        }
    }

    &.history {
        table td strong {
            font-size: 1.1em;
        }
    }

    &.responsible-persons {
        table {
            thead {
                background: $kae-dark-blue;
                color: #ffffff;
            }
            tbody tr td:first-child {
                background: $kae-blue;
                color: #ffffff;
            }
        }
    }
}

body main section.test {
    position: relative;
    .font-panel {
        position: fixed;
        right: 15%;
        top: 20%;
        background: $kae-dark-blue;
        padding: 1.5rem;
        color: #ffffff;
        z-index: 100000;

        button {
            display: block;
            margin: 0.5rem auto;
            background: $kae-green;
            border: none;
            padding: 0.5rem;
            color: #ffffff;
            width: 100%;

            &:first-of-type {
                margin-top: 1.5rem;
            }
        }
    }

    article {
        font-weight: 400 !important;

        a {
            color: #0568ba;
        }
        p {
            font-family: 'Roboto', sans-serif !important;
            font-size: 1.1em !important;
        }
        h1 {
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-dark-blue;
            font-size: 2.2em !important;
            margin: 0.5em 0;
        }
        h2 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-blue;
            font-size: 1.6em !important;
            margin: 1.1em 0 0.5em;
        }
        h3, h4 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-green;
            font-size: 1.35em !important;
            margin: 1.7em 0 0.5em;
        }
    }
}