@mixin box-shadow ($right, $top, $blur, $color) {
    -webkit-box-shadow: $right $top $blur 0 $color;
    -moz-box-shadow:    $right $top $blur 0 $color;
    box-shadow:         $right $top $blur 0 $color;
}

@mixin clearfix {
    &:after {
        content: ' ';
        display: table;
        clear: both
    }
}