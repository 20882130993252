body > header {
    @include clearfix();

    .inner-container {
        background-color: #ffffff;
        position: relative;
        padding-bottom: 1.5rem;

        @media only screen and (max-width: 1090px) {
            padding-bottom: 2.5rem;
        }
        @media only screen and (max-width: 500px) {
            padding: 0
        }
    }

    .header__top {
        padding: 0.7rem 2rem 1rem;
        @include clearfix();

        @media only screen and (max-width: 500px) {
            padding: 1.5rem 1.5rem 4rem;
            position: relative;
        }

        img {
            width: 100%;
            display: block;
        }

        .header__top--logo {
            @include span(1.5);

            @media only screen and (max-width: 500px) {
                width: span(3);
            }
        }

        h1 {
            box-sizing: border-box;
            float: left;
            line-height: 1em;
            margin: 1.1rem 0 0 span(0.5);
            font-size: 1em;
            text-transform: uppercase;
            font-family: 'Roboto Slab', serif;
            font-weight: 700;

            @media only screen and (max-width: 1090px) {
                margin-top: 1.5rem;
            }
            @media only screen and (max-width: 500px) {
                position: absolute;
                width: calc(100% - 3rem);
                text-align: center;
                margin: 0;
                bottom: 1.2rem;
            }
        }

        .lang-panel {
            @include span(2.5 last);
            text-align: right;
            margin-top: 0.8rem;
            font-family: 'Roboto Slab', serif;
            font-weight: 400;

            @media only screen and (max-width: 1090px) {
                margin-top: 1.1rem;
            }
            @media only screen and (max-width: 500px) {
                width: span(7);
                position: relative;
                margin: 0.9rem 0 0;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    font-size: 1.2em;

                    a {
                        color: $kae-blue;
                        line-height: 1.4em;
                        padding: 0.5rem;

                        &.lang-active {
                            color: $kae-dark-blue;
                        }
                    }
                }
            }
        }
    }

    .header__nav {
        width: span(9.8);
        background-color: $kae-blue;
        color: #ffffff;
        position: absolute;
        bottom: 0;
        z-index: 100;
        right: 0;
        font-family: 'Roboto Slab', serif;
        font-weight: 400;

        @media only screen and (max-width: 1090px) {
            width: span(12);
        }
        @media only screen and (max-width: 500px) {
            display: none;
        }

        &:before {
            content: '';
            position: absolute;
            left: -2.96em;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 3em 3em;
            border-color: transparent transparent $kae-blue transparent;
        }

        ul {
            margin: 0;
            padding: 0;
            @include clearfix();

            li {
                float: left;
                list-style: none;

                & > a {
                    padding: 1em 1em;
                    line-height: 3em;
                    font-size: 1em;
                    text-transform: uppercase;

                    &:hover, &:focus {
                        text-decoration: none;
                        color: #ffffff;
                    }

                    img {
                        width: 1em;
                        height: 1em;
                        vertical-align: middle;
                    }
                }

                &:hover, &.active {
                    background: $kae-dark-blue;
                }
            }
        }
    }
}