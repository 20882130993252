.carousel {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    @include clearfix();

    .carousel__body {
        position: relative;
        top: 0;
        left: 0;
        @include clearfix();

        .carousel__item {
            width: 100%;
            float: left;
            position: relative;
        }
    }
}