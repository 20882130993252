.section-page {
    .inner-container {
        background: #ffffff;
    }

    article {
        padding: 2rem 4.5rem 2.5rem;
        font-size: 1.1em !important;

        @media only screen and (max-width: 500px) {
            padding: 1rem 1.5rem 1rem;
        }

        a {
            color: #0568ba;
        }

        h1 {
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-dark-blue;
            font-size: 2.15em !important;
            margin: 0.5em 0;
        }
        h2 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-blue;
            font-size: 1.55em !important;
            margin: 1.1em 0 0.5em;
        }
        h3, h4 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-green;
            font-size: 1.35em !important;
            margin: 1.7em 0 0.5em;
        }

        p {
            text-align: justify;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400 !important;
        }

        p:first-of-type .fancybox img {
            width: 40%;
            margin: 1.6rem 0 1rem 2rem;
            float: right;

            @media only screen and (max-width: 800px) {
                width: 100%;
                margin: 0 0 2rem 0;
            }
        }

        iframe {
            width: 600px !important;
            height: 330px !important;
            margin: 3rem auto 1rem;
            display: block;

            @media only screen and (max-width: 500px) {
                width: 100% !important;
            }
        }

        img {
            width: 100%;
            margin: 1rem 0;
        }

        ul, ol {
            padding: 0 0 0 2rem !important;

            li {
                margin: 1rem 0 !important;
            }
        }

        p + h2, ul + h2, ol + h2 {
            margin-top: 2.5rem;
        }

        table {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;

            td {
                border: 1px solid #000000;
                padding: 0.5rem 1rem;

                p {
                    margin: 0 !important;
                }

                img {
                    width: 100% !important;
                    float: none !important;
                    margin: 0 0 1rem !important;
                }

                h1, h2, h3, h4 {
                    margin: 0 0 0.5em;
                }
            }

            th {
                border: 1px solid #000000;
                padding: 0.7rem;
                font-size: 1.1em;
            }

            tbody tr:hover {
                background: rgba($kae-light-blue, 0.5);
            }
        }

        a.doc {
            color: #0071bc;
            background-size: 1rem 1.1rem !important;
            padding-left: 1.6rem;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
            &.word {
                background: url("/media/img/doc.svg") 0 3px no-repeat;
            }
            &.xls {
                background: url("/media/img/xls.svg") 0 3px no-repeat;
            }
            &.pdf {
                background: url("/media/img/pdf.svg") 0 3px no-repeat;
            }
        }
    }

}