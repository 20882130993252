.news-article {

    .page__title {
        span {
            margin-right: 1.3rem;
            line-height: 1em;
        }

        a:hover {
            text-decoration: none;
        }
    }

    .news-article__header {
        background-size: cover !important;
        background-position: center 20% !important;
        padding-top: 4rem;

        .article-header__text {
            background: rgba(#000000, 0.6);
            color: #ffffff;
            padding: 1.5rem 2rem;

            @media only screen and (max-width: 500px) {
                padding: 1.5rem;
            }

            h3 {
                font-size: 1.7em;
                margin: 0 0 0.5rem;
                text-transform: uppercase;
                font-family: 'Roboto Slab', serif !important;
                font-weight: 700 !important;
            }
        }
    }

    .article-body__text {
        background: $kae-light-blue;
        padding: 2rem 4.5rem 2.5rem;

        @media only screen and (max-width: 500px) {
            padding: 1rem 1.5rem 1rem;
        }

        p {
            text-align: justify;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400 !important;
            font-size: 1.15em  !important;
        }
    }

    .news-gallery__title {
        text-transform: uppercase;
        margin: 1.5rem 0.5rem;
        font-size: 1.5em;

        @media only screen and (max-width: 500px) {
            margin: 1.5rem;
        }
    }
    .news-gallery__body {
        @include clearfix();

        figure {
            cursor: pointer;
            @include span(4);
            margin: 0;

            @media only screen and (max-width: 500px) {
                width: span(6);
            }

            img {
                width: 100%;
                display: block;
            }
        }
    }
}