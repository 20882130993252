.news__all-item {
    @include clearfix();
    display: block;
    position: relative;

    @media only screen and (max-width: 500px) {
        padding-bottom: 1.5rem;
    }

    &:nth-child(odd) {
        background-color: #ffffff;
    }
    &:nth-child(even) {
        background-color: $kae-light-blue;
    }

    &:hover {
        button {
            opacity: 1;
        }
    }

    .news-image {
        @include span(3);

        img {
            display: block;
            width: 100%;
        }
    }

    .news-content {
        @include span(9);
        padding: 1rem span(0.5);

        h3 {
            font-size: 1.3em;
            text-transform: uppercase;
            font-family: 'Roboto Slab', serif;
            font-weight: 700;

            @media only screen and (max-width: 500px) {
                margin-top: 0;
            }
        }

        div {
            text-align: justify;
            color: #6e6e6e;
            margin: 0 0 1rem;
        }

        .news-date {
            margin-top: 1rem;
            color: #0e669d;
            display: block;
        }
    }

    button {
        opacity: 0;
        position: absolute;
        right: 1.5rem;
        bottom: 2rem;
        cursor: pointer;
        font-size: 1em;
        padding: 0.4rem 0.8rem;
        border: none;
        color: #ffffff;
        background-color: $kae-green;
    }
}