$laptop--l-w: 1440px;
$laptop-w: 1024px;
$tablet-w: 768px;
$mobile-l-w: 425px;
$mobile-m-w: 375px;
$mobile-s-w: 320px;

// colors
$kae-blue: #0e669d;
$kae-dark-blue: #013555;
$kae-light-blue: #e8fcff;
$kae-green: #009b47;
$kae-dark-green: #005a29;
$kae-orange: #c29b00;

$footer-gray: #ececec;
$footer-text-gray: #86888a;