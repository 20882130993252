#pagination {
    background: #ffffff;
    padding: 1.7rem 0 1.2rem;
    text-align: center;

    ul.pagination {
        @include clearfix();
        display: inline-block;
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            float: left;

            a {
                display: inline-block;
                text-align: center;
                width: 2.2rem;
                height: 2.2rem;
                color: #ffffff;
                font-size: 1.15em;
                line-height: 2.2rem;
                text-decoration: none;
                background: $kae-dark-blue;
                margin: 0 4px 0 0;
                position: inherit;
                float: none;
                padding: 0;

                &.active-page {
                    background: $kae-blue;
                }
            }
        }
    }
}