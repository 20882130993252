.header__nav-mobile {
    width: span(12);
    background-color: $kae-blue;
    color: #ffffff;
    position: relative;
    bottom: 0;
    z-index: 100;
    right: 0;
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    display: none;

    @media only screen and (max-width: 500px) {
        display: block;
    }

    & > ul:first-child {
        li:nth-child(2) {
            width: calc(100% - 5.4rem);
        }
    }

    ul {
        margin: 0;
        padding: 0;
        @include clearfix();

        li {
            float: left;
            list-style: none;
            width: 2.7rem;

            button {
                background: none;
                border: none;
                color: #ffffff;
                padding: 0.455rem 1rem;
                width: 100%;

                span {
                    line-height: 1.6rem;
                    vertical-align: top;
                }
                img {
                    width: 1.5rem;
                    height: 1.5rem;
                    margin-right: 0.4rem;
                }
            }

            & > a {
                padding: 1em 1em;
                line-height: 3em;
                font-size: 1em;
                text-transform: uppercase;

                &:hover, &:focus {
                    text-decoration: none;
                    color: #ffffff;
                }

                img {
                    width: 1em;
                    height: 1em;
                    vertical-align: middle;
                }
            }

            &.active {
                background: $kae-dark-blue;
            }
        }
    }

    .mobile-nav--dropdown {
        position: absolute;
        margin: 0;
        padding: 1.2rem 0;
        @include clearfix();
        width: 100%;
        background: $kae-blue;

        display: none;

        &.active {
            display: block;
        }

        li {
            display: block;
            list-style: none;
            width: 100%;

            &.active {
                background: $kae-dark-blue;

                & > ul {
                    display: block;
                }
            }

            a {
                display: block;
                padding: 0 1.5rem;
            }

            ul {
                margin: 0;
                padding: 0;
                @include clearfix();
                width: 100%;
                background: $kae-blue;
                display: none;

                li {
                    display: block;
                    list-style: none;
                    width: 100%;

                    a {
                        display: block;
                        padding: 0.5rem 1.5rem;
                        position: relative;

                        background-size: cover !important;
                        background-position: center center !important;
                        background-repeat: no-repeat !important;

                        span {
                            z-index: 1;
                            position: relative;
                        }

                        &:after {
                            content: "";
                            display: block;
                            height: 100%;
                            width: 100%;
                            background: rgba($kae-blue, 0.6);
                            -moz-transition: all 0.3s;
                            -webkit-transition: all 0.3s;
                            transition: all 0.3s;
                            top: 0;
                            left: 0;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }
}