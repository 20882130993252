.product-view {

    .product-view__header {
        background-size: cover !important;
        background-position: center !important;
        padding-top: 15rem;

        .product-view-header__text {
            background: rgba(#000000, 0.6);
            color: #ffffff;
            padding: 1.5rem 2rem;

            @media only screen and (max-width: 500px) {
                padding: 1.5rem;
            }

            h3 {
                font-size: 1.7em;
                margin: 0 0 0.5rem;
                text-transform: uppercase;
                font-family: 'Roboto Slab', serif !important;
                font-weight: 700 !important;
            }
        }
    }

    .product-view__body {
        background: $kae-light-blue;
        padding: 2rem 4.5rem 2.5rem;

        @media only screen and (max-width: 500px) {
            padding: 1rem 1.5rem 1rem;
        }

        p {
            text-align: justify;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400 !important;
            font-size: 1.15em  !important;
        }

        iframe {
            width: 600px !important;
            height: 330px !important;
            margin: 3rem auto 1rem;
            display: block;

            @media only screen and (max-width: 500px) {
                width: 100% !important;
            }
        }

        .product__specifications {
            width: 100%;
            border-spacing: 0;
            border-collapse: collapse;
            margin: 3rem 0;

            td {
                border: 1px solid #000000;
                padding: 0.5rem;

                &.specifications-title {
                    text-align: center;
                    font-size: 1.1em;
                    padding: 0.6rem 0;
                    background: #013555 !important;
                }
            }

            th {
                border: 1px solid #000000;
                padding: 0.8rem;
                font-size: 1.2em;
            }

            thead {
                background: $kae-dark-blue;
                color: #ffffff;
            }
            tbody tr td:first-child {
                background: $kae-blue;
                color: #ffffff;
            }
            tbody tr td:nth-child(2) {
                text-align: center;
            }
        }
        .product__options {
            @include clearfix();

            h3 {
                font-size: 1.4em;
                text-transform: uppercase;
                margin: 0;
            }

            .product__options--standard, .product__options--additional {
                @include span(6);
                padding: 0 1rem;

                @media only screen and (max-width: 500px) {
                    width: span(12);
                }
            }
        }
    }

    .product-gallery__title {
        text-transform: uppercase;
        margin: 1.5rem 0.5rem;
        font-size: 1.5em;

        @media only screen and (max-width: 500px) {
            margin: 1.5rem;
        }
    }
    .product-gallery__body {
        @include clearfix();

        figure {
            cursor: pointer;
            @include span(4);
            margin: 0;

            @media only screen and (max-width: 500px) {
                width: span(6);
            }

            img {
                width: 100%;
                display: block;
            }
        }
    }
}