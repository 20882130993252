.index__news {
    @include clearfix();

    .inner-container {
        background: #ffffff;
    }

    .index__all-news, .index__main-news, .index__exposition-news {
        @include span(6);
        float: right;

        @media only screen and (max-width: 1090px) {
            width: span(12);
        }

        h1 {
            display: inline-block;
            color: #ffffff;
            font-size: 1.4em;
            line-height: 1.2em;
            text-transform: uppercase;
            margin: 0;
            padding: 0.6em 1.3rem;
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;

            position: relative;

            &:before {
                content: '';
                position: absolute;
                right: -4.2em;
                top: 0;
                width: 1.5em;
                height: 0;
                border-style: solid;
                border-width: 0 3em 2.4em 0;
                border-color: transparent transparent #fff transparent;
            }
            &:after {
                content: '';
                position: absolute;
                right: -2.7em;
                top: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 3em 2.4em 0;
            }
        }
    }

    .index__all-news {
        background: $kae-dark-blue;
        background: -moz-linear-gradient(top, $kae-dark-blue 0, $kae-dark-blue 2.2em, $kae-blue 2.2em, $kae-blue 3.35em, #ffffff 3.35em, #ffffff 100%);
        background: -webkit-linear-gradient(top, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-blue 2.2em, $kae-blue 3.35em,#ffffff 3.35em,#ffffff 100%);
        background: linear-gradient(to bottom, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-blue 2.2em, $kae-blue 3.35em,#ffffff 3.35em,#ffffff 100%);

        h1 {
            background: $kae-blue;

            &:after {
                border-color: transparent transparent $kae-blue transparent;
            }
        }
    }

    .index__main-news {
        background: $kae-dark-blue;
        background: -moz-linear-gradient(top, $kae-dark-blue 0, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em, #ffffff 3.35em, #ffffff 100%);
        background: -webkit-linear-gradient(top, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em,#ffffff 3.35em,#ffffff 100%);
        background: linear-gradient(to bottom, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em,#ffffff 3.35em,#ffffff 100%);

        h1 {
            background: $kae-green;

            &:after {
                border-color: transparent transparent $kae-green transparent;
            }
        }
    }

    .index__exposition-news {
        @include span(12);

        background: $kae-dark-blue;
        background: -moz-linear-gradient(top, $kae-dark-blue 0, $kae-dark-blue 2.2em, $kae-orange 2.2em, $kae-orange 3.35em, #ffffff 3.35em, #ffffff 100%);
        background: -webkit-linear-gradient(top, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-orange 2.2em, $kae-orange 3.35em,#ffffff 3.35em,#ffffff 100%);
        background: linear-gradient(to bottom, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-orange 2.2em, $kae-orange 3.35em,#ffffff 3.35em,#ffffff 100%);

        h1 {
            background: $kae-orange;

            &:after {
                border-color: transparent transparent $kae-orange transparent;
            }
        }
    }
}