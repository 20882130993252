.gallery__photos-block {
   @include clearfix();
    margin-bottom: 1.5rem;
}

.gallery__photos-item {
    @include span(4);
    height: 12rem;
    color: #FFFFFF;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    position: relative;
    background-position: center !important;
    background-size: cover !important;

    @media only screen and (max-width: 500px) {
        width: span(6);
    }

    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0;
        background: rgba(0,0,0,0.6);
        -moz-transition: all 0.3s;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        top: 0;
        left: 0;
        position: absolute;
    }
    &:hover {

        h3 {
            opacity: 1;
        }

        &:after {
            opacity: 1;
        }
    }

    h3 {
        z-index: 1;
        position: relative;

        opacity: 0;
        -moz-transition: all 0.5s;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;

        text-transform: uppercase;
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        font-size: 1.5em;
        margin: 0;
        text-align: left;
        display: inline-block;
        vertical-align: middle;
        line-height: 1em;
    }
}