.index__all-news--item {
    @include clearfix();
    display: block;
    position: relative;

    &:hover {
        background-color: $kae-light-blue;

        button {
            opacity: 1;
        }
    }

    .news-image {
        @include span(3);

        @media only screen and (max-width: 1090px) {
            width: span(1.8);
        }
        @media only screen and (max-width: 500px) {
            width: span(4);
        }

        img {
            display: block;
            width: 100%;
        }
    }

    .news-content {
        @include span(9);
        padding: 1rem span(0.5);

        @media only screen and (max-width: 500px) {
            padding: 0.6rem;
        }

        @media only screen and (max-width: 1090px) {
            width: span(10.12);
        }
        @media only screen and (max-width: 500px) {
            width: span(8);
        }

        h3 {
            font-size: 1em;
            text-transform: uppercase;
            font-family: 'Roboto Slab', serif;
            font-weight: 700;

            @media only screen and (max-width: 500px) {
                margin: 0;
            }
        }

        div {
            text-align: justify;
        }

        .news-date {
            margin-top: 1rem;
            color: #0e669d;
            display: block;
        }
    }

    button {
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: 1rem;

        cursor: pointer;
        font-size: 1em;
        padding: 0.4rem 0.8rem;
        border: none;
        color: #ffffff;
        background-color: $kae-blue;
    }
}