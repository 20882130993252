$susy: (
        columns: 12,
        container: 1100px,
        container-position: center,
        gutters: 0,
        math: fluid,
        output: float,
        gutter-position: inside
);

.inner-container {
  @include container();
}