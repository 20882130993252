.products-page {
    .products__body {
        display: table;

        @media only screen and (max-width: 500px) {
            display: block;
        }

        .products__menu {
            display: table-cell;
            vertical-align: top;
            width: span(3.5);
            background: $kae-blue url('/media/img/products-menu-bg.png') no-repeat;
            background-position-y: 100%;
            background-size: inherit;

            @media only screen and (max-width: 500px) {
                display: none;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;
                margin-top: 4rem;

                li {
                    color: #ffffff;
                    line-height: 1.5rem;
                    position: relative;

                    a {
                        display: block;
                        padding: 1rem 1.5rem;
                        text-transform: uppercase;
                    }

                    .products-menu__dropdown {
                        z-index: 10;
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 100%;
                        min-width: 150%;
                        padding: 1.5rem 1.5rem 1.5rem 2rem;
                        background: $kae-dark-blue;

                        &.open {
                            display: table;
                        }

                        .menu-dropdown__info {
                            display: table-cell;
                            width: 65%;
                            vertical-align: top;
                            h3 {
                                font-family: 'Roboto Slab', serif;
                                font-weight: 400;
                                font-size: 1.3em;
                                margin: 0 0 0.6rem;
                                text-transform: uppercase;
                            }
                            div {
                                line-height: 1.4em;
                                text-align: justify;
                            }
                        }
                        .menu-dropdown__image {
                            display: table-cell;
                            width: 35%;
                            padding-left: 1.5rem;

                            img {
                                width: 100%;
                            }
                        }
                    }

                    &.active, &:hover {
                        background: $kae-dark-blue;
                    }
                }
            }
        }
        .products__list {
            display: table-cell;
            position: relative;
            background: #ffffff;

            @media only screen and (max-width: 500px) {
                display: block;
            }

            #pagination {
                background: #fff;
                padding: 0.9rem 1rem;
                position: absolute;
                top: 0;
                left: 0;
                width: span(7);
                text-align: left;

                @media only screen and (max-width: 500px) {
                    width: 100%;
                    text-align: center;
                    position: relative;
                }
            }

            #pagination_content {
                margin-top: 4rem;

                @media only screen and (max-width: 500px) {
                    margin-top: 0;
                }
            }

            .projects__download {
                position: absolute;
                top: 0;
                right: 0;
                width: span(5);
                text-align: right;

                @media only screen and (max-width: 500px) {
                    width: 100%;
                    text-align: center;
                    position: relative;
                }

                a {
                    display: inline-block;
                    background: $kae-orange;
                    color: #ffffff;
                    text-transform: uppercase;
                    padding: 0.5rem 1rem;
                    margin: 1rem 1rem 1rem 0;
                }
            }
        }
    }
}