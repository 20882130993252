body > footer {
    .inner-container {
        background-color: $footer-gray;
        color: $footer-text-gray;
        padding: 2rem 3rem;

        @media only screen and (max-width: 500px) {
            padding: 3rem 1.5rem 1rem;
        }
    }

    .footer__top {
        width: 100%;
        padding: 0 0 2rem;

        @include clearfix();

        .footer__sitemap {
            @include span(8);
            font-size: 1.1em;
            border-right: 1px solid $footer-text-gray;

            @media only screen and (max-width: 500px) {
                width: 100%;
                border: none;
                border-bottom: 1px solid $footer-text-gray;
            }

            ul {
                display: inline-block;
                width: calc(33% - 2rem);
                vertical-align: top;
                padding: 0;
                margin: 0 1.8rem 1.2rem 0;
                list-style: none;

                @media only screen and (max-width: 500px) {
                    width: calc(50% - 2.4rem);
                    margin: 0 1rem 2rem;

                    li {
                        margin: 0.5rem 0;
                    }
                }

                li:first-child {
                    color: #000000;
                    font-family: 'Roboto Slab', serif !important;
                    font-weight: 400 !important;
                    font-size: 1.2em;
                    margin-bottom: 0.4rem;
                }
            }
        }

        .footer__contacts {
            @include span(4);

            @media only screen and (max-width: 500px) {
                width: 100%;
                padding: 2rem 0 0;
            }

            #kae-map {
                margin: 0;
                padding: 0;
                width: 100%;
                height: 9rem;
            }

            .footer-contacts__download {
                display: block;
                width: 80%;
                text-align: center;
                margin: 1rem auto;
                padding: 0.6rem 1.8rem;
                cursor: pointer;
                background-color: $kae-green;
                color: #ffffff;

                @media only screen and (max-width: 500px) {
                    margin-bottom: 3rem;
                }

                &:hover {
                    background: $kae-dark-green;
                    text-decoration: none;
                }
            }

            padding-left: 1.5rem;

            h3 {
                color: #000000;
                font-size: 1.2em;
                margin-bottom: 0.4rem;
                margin-left: 1.5rem;
                font-family: 'Roboto Slab', serif !important;
                font-weight: 400 !important;
            }

            & > div {
                text-align: left;
                background-position: left top;
                padding-left: 2rem;
                margin: 1rem 0;
            }

            .footer__contacts--address {
                background: url("/media/img/footer-contacts-location-icon.png") no-repeat;
            }

            .footer__contacts--phones {
                background: url("/media/img/footer-contacts-phones-icon.png") no-repeat;
            }

            .footer__contacts--emails {
                background: url("/media/img/footer-contacts-emails-icon.png") no-repeat;
            }
        }
    }

    .footer__bottom {
        width: 100%;
        padding: 1rem 0 0;
        border-top: 1px solid $footer-text-gray;

        img.footer__dark-logo {
            width: span(1);
            display: inline-block;

            @media only screen and (max-width: 500px) {
                width: 25%;
            }
        }
        p {
            width: span(6);
            display: inline-block;
            margin: 0 span(0.3);

            @media only screen and (max-width: 500px) {
                width: 65%;
                margin: 0 4%;
            }
        }

        .soc-icon {
            float: right;
            margin-left: 0.8rem;

            @media only screen and (max-width: 500px) {
                margin: 2.5rem 0.5rem 0;
            }

            img {
                width: 100%;
            }
        }
    }
}