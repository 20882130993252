.index__main-news--item {
    @include clearfix();
    display: block;
    position: relative;
    color: #ffffff;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 1rem 0 0;

    @media only screen and (max-width: 1090px) {
        background-position: center 25% !important;
    }
    @media only screen and (max-width: 500px) {
        background-position: 0!important;
        padding: 0 0 3rem;
    }

    &:after {
        content: "";
        display: block;
        height: 100%;
        width: 100%;
        opacity: 0;
        background: rgba($kae-blue,0.55);
        top: 0;
        left: 0;
        position: absolute;

        @media only screen and (max-width: 1090px) {
            opacity: 0.8;
        }
    }
    &:hover {
        text-decoration: none;

        button, p {
            opacity: 1;
        }

        &:after {
            opacity: 1;
        }
    }

    h3 {
        background: rgba(0,0,0,0.75);
        width: 75%;
        padding: 0.7rem 1rem 0.7rem 1.5rem;
        font-size: 1.2em;
        text-transform: uppercase;
        margin: 0;
        z-index: 1;
        position: relative;
        font-family: 'Roboto Slab', serif;
        font-weight: 700;

        @media only screen and (max-width: 500px) {
            width: 100%;
        }
    }

    p {
        text-align: justify;
        opacity: 0;
        width: 75%;
        padding: 0.4rem 1rem 0.4rem 1.5rem;
        z-index: 1;
        position: relative;

        @media only screen and (max-width: 1090px) {
            opacity: 1;
        }
        @media only screen and (max-width: 500px) {
            width: 100%;
            margin: 0;
            padding: 0.8rem;
        }
    }

    button {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: 1rem;
        z-index: 1;
        cursor: pointer;
        font-size: 1em;
        padding: 0.4rem 0.8rem;
        border: none;
        background-color: $kae-blue;
        color: #ffffff;

        @media only screen and (max-width: 1090px) {
            opacity: 1;
        }
    }
}