.search-page {

    .search-form {
        padding: 2rem 3rem 4rem;
        @include clearfix();

        input[type=text] {
            width: 100%;
            display: block;
            font-size: 1.1em;
            padding: 0.5rem;
        }

        .search-page__checkbox {
            @include span(4);
            margin: 1.5rem 0 2rem;

            label {
                cursor: pointer;
            }
        }

        .search-form__button {
            cursor: pointer;
            border: none;
            background: $kae-green !important;
            padding: 0.7rem 1.7rem;
            color: #ffffff;
            text-transform: uppercase;
            text-align: center;

            &:hover {
                background: $kae-dark-green !important;
            }
        }
    }

    .search-page__results {
        h3 {
            margin: 2rem;
        }
    }
}