body {
    font-size: 14px; //base font size
    font-family: 'Roboto', sans-serif;
    font-weight: 400 !important;
    margin: 0;

    background: url("/media/img/back-pattern.jpg") no-repeat;
    background-size: 100%;
}

html, body {
    height: 100%;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: underline;
    }
}

section#index {
    position: relative;
    //top: -1.5rem;
}

.inner-container {
    background: #ffffff;
}

.va-middle {
    display: inline-block;
    width: 0.1%;
    height: 100%;
    vertical-align: middle;
}