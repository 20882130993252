.header__nav--dropdown {
    position: absolute;
    top: 3em;
    left: 3em;
    width: calc(100% - 3em);
    @include clearfix();

    display: none;

    h3 {
        text-transform: uppercase;
    }

    &.open {
        display: block;
    }

    .items {
        //@include span(6);
        //@include clearfix();

        background: $kae-dark-blue;
        max-width: 800px;
        float: left;

        .item {
            //@include span(6);
            width: 160px;
            float: left;
            height: 160px;
            position: relative;
            text-align: center;

            background-repeat: no-repeat !important;
            background-size: cover !important;
            background-position-x: center !important;

            &:after {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                background: rgba($kae-blue, 0.6);
                -moz-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                top: 0;
                left: 0;
                position: absolute;
            }
            &:hover {
                text-decoration: none;

                h3 {
                    font-size: 0.85em;
                }

                &:after {
                    background: rgba($kae-dark-blue, 0.6);
                }
            }

            h3 {
                display: inline-block;
                width: 95%;
                font-size: 0.7em;
                line-height: 1.6em;
                z-index: 1;
                position: relative;
                margin: 0;
            }
        }
    }
}