.index__video-container {
    height: 520px;
    overflow: hidden;

    @media only screen and (max-width: 500px) {
        height: auto;
    }

    .inner-container {
        background: #ddd url("/media/img/index-video-poster.jpg") no-repeat;
        background-size: cover;
        min-height: 33rem;
        position: relative;

        @media only screen and (max-width: 500px) {
            min-height: 20rem;
        }
    }
    .index__video {
        min-width: 100%;
        min-height: 80%;
        width: auto;
        height: auto;
        margin-top: -5%;

        @media only screen and (max-width: 1090px) {
            width: 140%;
            margin: -5% -15% 0;
        }
    }

    #video__big-play-icon {
        position: absolute;
        left: calc(50% - 30px);
        top: 222px;
        z-index: 1;
        cursor: pointer;

        @media only screen and (max-width: 500px) {
            top: 6rem;
        }
    }

    .index-video__controls {
        background: rgba(#000, 0.6); /* Old browsers */
        background: -moz-linear-gradient(top, transparent 0%, #000000 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, transparent 0%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, transparent 0%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        position: absolute;
        height: 8rem;
        width: 100%;
        bottom: 2.7rem;
        padding: 3.5rem 1.5rem 1.5rem;

        display: table;

        @media only screen and (max-width: 500px) {
            background: #000;
            padding: 1rem;
            bottom: 0;
            height: auto;
        }

        .soc-icon {
            display: table-cell;
            width: 3rem;
            text-align: center;
            vertical-align: middle;

            @media only screen and (max-width: 500px) {
                display: none;
            }

            img {
                width: 2.5rem;
                vertical-align: middle;
            }
        }

        .controls-block {
            display: table-cell;
            width: calc(100% - 6rem);
            text-align: center;
            vertical-align: bottom;

            #video__play-pause {
                height: 33px;
                display: inline-block;
                margin-right: 1rem;
                vertical-align: middle;
                cursor: pointer;
            }

            #video__mute {
                height: 33px;
                display: inline-block;
                margin-left: 1rem;
                vertical-align: middle;
                cursor: pointer;
            }

            /*Chrome*/
            @media screen and (-webkit-min-device-pixel-ratio:0) {
                input[type='range'] {
                    cursor: pointer;
                    display: inline-block;
                    width: 70%;
                    vertical-align: middle;
                    overflow: hidden;
                    -webkit-appearance: none;
                    background-color: rgba(#ffffff, 0.6);
                }

                input[type='range']::-webkit-slider-runnable-track {
                    height: 12px;
                    -webkit-appearance: none;
                    color: #ffffff;
                    margin-top: -1px;
                }

                input[type='range']::-webkit-slider-thumb {
                    width: 10px;
                    -webkit-appearance: none;
                    height: 12px;
                    cursor: ew-resize;
                    background: #ffffff;
                    box-shadow: -1000px 0 0 1000px #ffffff;
                }

            }
            /** FF*/
            input[type="range"]::-moz-range-progress {
                background-color: #ffffff;
            }
            input[type="range"]::-moz-range-track {
                background-color: rgba(#ffffff, 0.6);
            }
            /* IE*/
            input[type="range"]::-ms-fill-lower {
                background-color: #ffffff;
            }
            input[type="range"]::-ms-fill-upper {
                background-color: rgba(#ffffff, 0.6);
            }
        }
    }
}