.news__main-block {

    .carousel__item {
        background-size: cover !important;

        .carousel__item-text {
            @include clearfix();
            background: rgba(#000000, 0.6);
            width: 100%;
            height: 60%;
            color: #ffffff;
            padding: 2rem 2.5rem;
            position: absolute;
            bottom: 0;

            @media only screen and (max-width: 500px) {
                padding: 1.5rem;
                height: 80%;
            }

            h3 {
                @include span(7);
                font-size: 1.5em;
                margin: 0 0 0.6rem;
                text-transform: uppercase;
                font-family: 'Roboto Slab', serif !important;
                font-weight: 700 !important;

                @media only screen and (max-width: 500px) {
                    width: span(12);
                }
            }
            p {
                @include span(7);
                text-align: justify;
                margin: 0.5rem 0 1rem;
            }

            .news-date {
                @include span(7);
            }

            a.more {
                position: absolute;
                right: 2rem;
                bottom: 1.5rem;
                cursor: pointer;
                font-size: 1em;
                padding: 0.5rem 0.8rem;
                border: none;
                color: #ffffff;
                background-color: $kae-blue;
            }
        }
    }
    .carousel__prev, .carousel__next {
        background: $kae-green;
        position: absolute;
        text-align: center;
        right: 0;
        color: #fff;
        width: 2.7rem;
        height: 2.7rem;
        line-height: 2.7rem;
        font-size: 1.3rem;
        cursor: pointer;
        transition: all .1s ease;

        &:hover {
            background: $kae-dark-green;
        }
    }

    .carousel__prev {
        top: calc(40% - 2.7rem);

        @media only screen and (max-width: 500px) {
            top: calc(20% - 2.7rem);
        }
    }
    .carousel__next {
        top: 40%;

        @media only screen and (max-width: 500px) {
            top: 20%
        }
    }

    .carousel__paging {
        display: none;
    }
}