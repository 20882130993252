.career-page {

    article {
        padding: 2rem 4.5rem 2.5rem;
        text-align: justify;
        font-size: 1.1em!important;

        h3 {
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;
            font-size: 1.4em !important;
            text-transform: uppercase !important;
        }
    }

    .career__footer {
        margin: 0 4.5rem 2rem;

        .career__contacts {
            line-height: 1.5rem;
            font-size: 1.15em;
        }

        .career__form {
            display: inline-block;
            margin: 1rem 0 3em;
            font-size: 1.2em;
            padding: 0.8rem 1.8rem;
            cursor: pointer;
            background-color: $kae-green;
            color: #ffffff;

            &:hover {
                background: $kae-dark-green;
            }
        }
    }
}