.gallery__video-block {
    background: #ececec;
    background-size: 100% 3.35em;

    .gallery_youtube-icon {
        float: right;
        height: 2rem;
        margin-top: 0.5rem;
        margin-right: 0.5rem;
    }

    .gallery_to_chanel {
        background: $kae-green;
        color: #ffffff;
        text-transform: uppercase;
        padding: 0.3rem 0.8rem;
        float: right;
        margin-top: .7rem;
        margin-right: 2rem;

        &:hover {
            background: $kae-dark-green;
        }
    }

    .gallery-video__body {
        iframe {
            width: 33.1% !important;

            @media only screen and (max-width: 500px) {
                width: 49.3% !important;
            }
        }
    }
}