.page__breadcrumbs {
    background: #ececec;
    color: #484848;
    display: block;
    padding: 0.6em 2rem;

    @media only screen and (max-width: 500px) {
        padding: 0.6em 1.5rem;
    }

    ul {
        font-size: 1em;
        line-height: 1.6em;
        padding: 0;
        margin: 0;
        list-style: none;
        font-family: 'Roboto Slab', serif;
        font-weight: 400;
    }

    li {
        display: inline-block;

        &:not(:first-child):before {
            content: "⏵";
            padding: 0 0.4rem 0 0.2rem;
        }
    }
}