.index__partners {
    .inner-container {
        padding: 2rem 0;
        text-align: center;

        @media only screen and (max-width: 500px) {
            padding: 2rem;
        }
    }

    h3 {
        margin: 0;
        text-align: center;
        font-size: 1.4em;
        font-weight: 400!important;
        font-family: 'Roboto Slab',serif!important;
        color: #000;
    }

    .hi-icon {
        width: span(1.26);
        -webkit-transition: color 0.3s;
        -moz-transition: color 0.3s;
        transition: color 0.3s;
        display: inline-block;
        margin: 0 1.2rem;
        position: relative;
        z-index: 1;

        @media only screen and (max-width: 500px) {
            width: 42%;
            margin: 5% 3%;
        }

        &:after {
            //font-family: FontAwesome;
            color: #fff;
            font-size: 4em;
            top: 0;
            left: 0;
            //padding: 1rem;
            z-index: 2;
            background: rgba($kae-blue, 0.4);
            -webkit-transition: -webkit-transform 0.2s, opacity 0.3s;
            -moz-transition: -moz-transform 0.2s, opacity 0.3s;
            transition: transform 0.2s, opacity 0.3s;
            -webkit-transform: scale(1.2);
            -moz-transform: scale(1.2);
            -ms-transform: scale(1.2);
            transform: scale(1.2);
            opacity: 0;
            pointer-events: none;
            position: absolute;
            width: 100%;
            height: 100%;
            content: '\f0c1';
            //content: 'FUC';
            -webkit-box-sizing: content-box;
            -moz-box-sizing: content-box;
            box-sizing: content-box;
        }

        &:hover:after {
            -webkit-transform: scale(1);
            -moz-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
            opacity: 1
        }

        img {
            width: 100%;
        }
    }
}