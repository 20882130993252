.section-page.procurement, .section-page.regulatory-and-legal-framework {
    ul.procurement-type-select {
        padding: 0;
        margin: 0;
        background: $kae-blue;
        list-style: none;
        color: #ffffff;
        @include clearfix();

        li {
            @include span(6);
            text-align: center;
            padding: 1rem 1rem;
            text-transform: uppercase;
            cursor: pointer;

            &:hover, &.active {
                background: $kae-dark-blue;
            }
        }
    }

    article > div {
        display: none;

        &.active {
            display: block;
        }
    }

    table {
        thead {
            background: $kae-dark-blue;
            color: #ffffff;
            text-align: center;
        }
        tbody {
            tr:nth-child(odd) {
                background: $kae-light-blue;
            }
        }
    }
}