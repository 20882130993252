.certificate-page {

    .certificates__description {
        margin: 2rem;
        font-size: 1.1em !important;

        h1 {
            font-family: 'Roboto Slab', serif !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-dark-blue;
            font-size: 2.15em !important;
            margin: 0.5em 0;
        }
        h2 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-blue;
            font-size: 1.55em !important;
            margin: 1.1em 0 0.5em;
        }
        h3, h4 {
            font-family: 'Roboto Slab', cursive !important;
            font-weight: 700 !important;
            text-transform: uppercase;
            color: $kae-green;
            font-size: 1.35em !important;
            margin: 1.7em 0 0.5em;
        }

        p {
            text-align: justify;
            font-family: 'Roboto', sans-serif !important;
            font-weight: 400 !important;
        }

        ul, ol {
            padding: 0 0 0 2rem !important;

            li {
                margin: 1rem 0 !important;
            }
        }

        p + h2, ul + h2, ol + h2 {
            margin-top: 2.5rem;
        }
    }

    table {
        margin-bottom: 2rem;

        td {
            width: 30%;
            padding: 0.5rem 1.5rem;
            text-align: center;
        }

        img {
            width: 90%;
        }
    }
}