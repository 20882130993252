.index__exposition-news--item {
    @include clearfix();
    display: block;
    position: relative;

    .news-image {
        @include span(6);

        img {
            display: block;
            width: 100%;
        }
    }

    .news-content {
        @include span(5.2);
        background: #ffffff;
        padding: 1rem span(0.3);

        h3 {
            font-size: 1.5em;
            margin: 0.5rem 0;
            text-transform: uppercase;
            font-family: 'Roboto Slab', serif;
            font-weight: 700;
        }

        p {
            text-align: justify;
            margin: 0.8rem 0 1rem;
        }

        .news-date {
            color: #6e6e6e;
        }
    }

    button {
        position: absolute;
        right: span(0.8);
        bottom: 1rem;

        cursor: pointer;
        font-size: 1em;
        padding: 0.4rem 0.8rem;
        border: none;
        color: #ffffff;
        background-color: $kae-blue;
    }
}