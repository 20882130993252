.page__header {
    background: $kae-dark-blue;
    background: -moz-linear-gradient(top, $kae-dark-blue 0, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em, #ffffff 3.35em, #ffffff 100%);
    background: -webkit-linear-gradient(top, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em,#ffffff 3.35em,#ffffff 100%);
    background: linear-gradient(to bottom, $kae-dark-blue 0%, $kae-dark-blue 2.2em, $kae-green 2.2em, $kae-green 3.35em,#ffffff 3.35em,#ffffff 100%);

    .page__title {
        display: inline-block;
        color: #ffffff;
        background: $kae-green;
        font-size: 1.4em;
        line-height: 1.2em;
        text-transform: uppercase;
        margin: 0;
        padding: 0.6em 1.3rem;
        font-family: 'Roboto Slab', serif;
        font-weight: 700;

        position: relative;

        &:before {
            content: '';
            position: absolute;
            right: -4.2em;
            top: 0;
            width: 1.5em;
            height: 0;
            border-style: solid;
            border-width: 0 3em 2.4em 0;
            border-color: transparent transparent #fff transparent;
        }
        &:after {
            content: '';
            position: absolute;
            right: -2.7em;
            top: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 3em 2.4em 0;
            border-color: transparent transparent $kae-green transparent;
        }
    }
}