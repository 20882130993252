section.index__banners {
    @include clearfix();

    .inner-container {
        padding: 5rem 2rem 2rem;
        text-align: center;
        background-color: #ffffff;
    }

    a {
        width: span(4);
        display: inline-block;
        margin: 0 0.8rem;

        @media only screen and (max-width: 500px) {
            width: 100%;
            margin: 5% 0;
        }

        img {
            max-width: 100%;
        }
    }
}