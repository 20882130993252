.page__sub-title {
    display: inline-block;
    color: #ffffff;
    background: $kae-blue;
    font-size: 1.4em;
    line-height: 1.2em;
    text-transform: uppercase;
    margin: 0;
    padding: 0.6em 1.3rem;
    font-family: 'Roboto Slab', serif;
    font-weight: 700;

    position: relative;

    &:before {
        content: '';
        position: absolute;
        right: -4.2em;
        top: 0;
        width: 1.5em;
        height: 0;
        border-style: solid;
        border-width: 0 3em 2.35em 0;
        border-color: transparent transparent #fff transparent;
    }
    &:after {
        content: '';
        position: absolute;
        right: -2.7em;
        top: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3em 2.35em 0;
        border-color: transparent transparent $kae-blue transparent;
    }
}