.products__menu-mobile {
    width: span(12);
    position: relative;
    bottom: 0;
    right: 0;
    font-family: 'Roboto Slab', serif;
    font-weight: 400;
    display: none;

    @media only screen and (max-width: 500px) {
        display: block;
    }

    & > button {
        background-color: $kae-blue;
        color: #ffffff;
        width: 100%;
        border: none;
        padding: 0.8rem 1rem;
        display: block;
        text-transform: uppercase;
    }

    .mobile-menu--dropdown {
        position: absolute;
        margin: 0;
        padding: 0.5rem 0 1.2em;
        @include clearfix();
        width: 100%;
        background: $kae-blue;
        z-index: 99;
        border-top: 2px solid $kae-dark-blue;

        display: none;

        &.active {
            display: block;
        }

        li {
            display: block;
            list-style: none;
            width: 100%;

            &.active {
                background: $kae-dark-blue;

                & > ul {
                    display: block;
                }
            }

            a {
                display: block;
                padding: 0 1.5rem;
                line-height: 3em;
                font-size: 1em;
                text-transform: uppercase;
                color: #ffffff;
            }
        }
    }
}

.mobile__category-title {
    width: span(12);
    font-size: 1.3em;
    padding: 1.5rem 1.5rem;
    display: none;

    @media only screen and (max-width: 500px) {
        display: block;
    }
}