#admin__login {
    form {
        text-align: center;
        margin: 4rem 0 5rem;

        h2 {
            margin: 0 0 1rem;
            font-size: 2em;
        }
        input {
            font-size: 1.2em;
            padding: 0.5rem;
            margin: 0.5rem 0;
            width: 45%;
        }

        button {
            display: inline-block;
            width: 30%;
            border: none;
            margin-top: 1.5rem;
            padding: 0.8rem 1.8rem;
            cursor: pointer;
            background-color: $kae-green;
            color: #ffffff;

            &:hover {
                background: $kae-dark-green;
            }
        }
    }
}

section.admin {

    ul.type-select {
        padding: 0;
        margin: 0;
        background: $kae-blue;
        list-style: none;
        color: #ffffff;
        @include clearfix();

        li {
            @include span(6);
            text-align: center;
            padding: 1rem 1rem;
            text-transform: uppercase;
            cursor: pointer;

            a, a:hover {
                color: #fff;
                display: block;
            }

            &:hover, &.active {
                background: $kae-dark-blue;
            }
        }
    }
}

#admin {
    section {
        .tactical_ru, .tactical_kz, .tactical_en {
            .value {
                margin: 0.5rem 0 !important;
            }
            .value.title {
                margin: 2rem 0 0.5rem !important;
            }
        }
    }
}